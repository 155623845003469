import { useState, useEffect } from 'react';
import useAuth from '../../models/auth';
import useNav from '../../models/nav';


export default function SettingsPage() {
	const auth = useAuth().watch(['session']);
	const nav = useNav();

	function reload() {
		window.location.reload();
	}

	useEffect(() => {
		nav.title = 'Settings';
	}, []);

	return (
		<>
			<div>Token: {auth.session?.access_token || 'no token'}</div>
			{auth.session && <div>Logged in! <button onClick={() => auth.signOut()}>Sign Out</button></div>}
			<button onClick={() => reload()}>Reload</button>
		</>
	);
	
}