import React, { useState, useEffect, useRef } from 'react';
import { useLoaderData, useParams, useLocation } from "react-router-dom";
import API from '../../api';
import useNav from '../../models/nav';
import Episode from '../episode';

export default function EpisodePage() {
	const params = useParams();
	const {episodeId} = params;

	let [loading, setLoading] = useState(true);
	let [processingTime, setProcessingTime] = useState(5);
	const [data, setData] = useState(null);
	const nav = useNav();
	const location = useLocation();

	async function fetch() {
		try {
			const r = await API.call('episode/get', { id: episodeId });
			setData(r);
		} catch (e) {
			console.log('- fail', e);
		}
		setLoading(false);
	};

	useEffect(() => {
		
		nav.title = '';

		// if we're coming from the inbox page, then we already have the basic info, so let's store it
		if (location.state?.episode) {
			setData(location.state.episode);
		}

		// no matter what, we need to get the full episode data with transcript
		fetch();

	}, []);

	return (
		<div key="episode-page" className="page">
			<div key="subtitle" className="subtitle">
				{data && (
					<>
						<Episode userEpisode={{episode: data}}/>
						<Player episode={data}/>
					</>
				)}
			</div>
			<hr/>
			episode stuff
		</div>
	);
};




