import { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { SendIntent } from "send-intent";
import useAuth from './models/auth';
import API from './api';
import appRoutes from './appRoutes';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

window.addEventListener("sendIntentReceived", () => {
	SendIntent.checkSendIntentReceived().then((result) => {
		if (result?.url) {
			console.log('SendIntent received from listener', result);
			let resultUrl = decodeURIComponent(result.url);
			window.location.href = '/ingest?url=' + resultUrl;
		}
	});
});

function App() {
	const auth = useAuth().watch(['session', 'sessionLoaded']);

	useEffect(() => {
		auth.getSession();
		auth.subscribeToChanges();

		return () => auth.unsubscribeFromChanges();
	}, [])

	async function googleInit() {
		GoogleAuth.initialize({
			clientId: '997941799460-nodcgjnms5nu5jst6k88f3u1m501gdh3.apps.googleusercontent.com',
			scopes: ['email', 'profile'],
			grantOfflineAccess: false,
		});
	}

	async function googleLogin() {
		const r = await GoogleAuth.signIn();
		console.log('- google login', r);

		if (r.authentication.idToken) {
			console.log('- google login with token', r.authentication.idToken);
			const { error, data: { user } } = await auth.client.auth.signInWithIdToken({ provider: 'google', token: r.authentication.idToken });
			if (error) {
				console.log('- supabase login with google token error', error);
			}
		}
	}

	async function appleLogin() {
		const nonce = Date.now() + 'sezhoo';
		console.log('- apple login');
		let options = {
			clientId: 'app.sezhoo.client',
			redirectURI: 'https://sezhoo.app',
			scope: 'email name',
			state: 'state',
		};

		try {
			const { response } = await SignInWithApple.authorize(options);
			console.log('- apple login response', response, response.identityToken);
			if (response.identityToken) {
				console.log('- apple login with token', response.identityToken);
				const { error, data: { user } } = await auth.client.auth.signInWithIdToken({ provider: 'apple', token: response.identityToken });
				if (error) {
					console.log('- supabase login with apple token error', error);
				}
			}
			
		} catch(e) {
			console.log('- login error', e);
		}
	}
	
	return (
		<>
		{!auth.session && !auth.sessionLoaded && <div>Loading...</div>}
		{!auth.session && auth.sessionLoaded && <div id="auth-login">
			<button onClick={appleLogin}>Apple Login</button>
			<button onClick={googleLogin}>Google Login</button>
			<button onClick={() => { window.location.reload()}}>Reload</button>
			<Auth
				supabaseClient={auth.client}
				appearance={{ 
					theme: ThemeSupa,
					className: {
						anchor: 'login-anchor',
					}
				}}
				providers={['google', 'apple']}
				magicLink={true}
				queryParams={{
					skipBrowserRedirect: true,
				}}
			/>
		</div>}
		{auth.session && <RouterProvider router={appRoutes} />}
		</>
	);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);