import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import API from '../../api';
import useNav from '../../models/nav';


export default function IngestPage() {
	const [url, setUrl] = useState('');
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const nav = useNav();

	useEffect(() => {
		nav.title = 'Ingest';
		const paramUrl = searchParams.get('url');
		if (paramUrl) {
			console.log('- paramUrl:', paramUrl);
			setUrl(paramUrl);
			submitUrl(paramUrl);
		}
	}, []);

	const onSubmit = async (e) => {
		e.preventDefault();

		if (!url) {
			return;
		}
		submitUrl(url);
	};

	const submitUrl = async (url) => {
		console.log('- url:', url);
		const r = await API.call('ingest/url', {url});
		navigate('/transcript/' + r.ep.id);
	}

	return (
		<>
			<div>
				<form onSubmit={onSubmit}>
					<input
						type="text"
						placeholder="https://"
						onChange={(e) => setUrl(e.target.value)}
					/>
					<button type="submit">Submit</button>
				</form>
			</div>
		</>
	);
};
