import React, { useState, useEffect } from 'react';
import { useLoaderData, useParams } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import API from '../../api';
import useNav from '../../models/nav';
import Episode from '../episode';

export default function InboxPage() {
	const params = useParams();
	const {episodeId} = params;

	let [loading, setLoading] = useState(true);
	let [processingTime, setProcessingTime] = useState(5);
	const [data, setData] = useState(null);
	const nav = useNav();

	async function fetch() {
		try {
			const r = await API.call('episode/getForUser');
			setData(r);
		} catch (e) {
			console.log('- fail', e);
		}
		setLoading(false);
	};

	useEffect(() => {
		nav.title = 'Inbox';

		fetch();

	}, []);

	return (
		<div key="inbox-page" className="page">
			{loading && (
				<div key="loading">Loading...</div>
			)}
			{!loading && data && (
				<div key="loaded">
					{data.map((ue) => (
						<Episode key={ue.episode.id} userEpisode={ue} />
					))}
				</div>
			)}
			{!loading && !data.length && (
				<div key="empty">You don't have any transcriptions yet. Add some by pressing the + button or sharing them to the Sezhoo app in the share sheet.</div>
			)}
		</div>
	);
};


