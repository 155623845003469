import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, NavLink } from 'react-router-dom';
import API from '../../api';
import useNav from '../../models/nav';
import useCache from '../../models/cache';


export default function SearchPage() {
	const [search, setSearch] = useState('');
	const [featuredPodcasts, setFeaturedPodcasts] = useState([]);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const nav = useNav();
	const cache = useCache();

	useEffect(() => {
		nav.title = 'Search';
		const s = searchParams.get('s');
		if (s) {
			console.log('- s from params:', s);
			setSearch(s);
			submitSearch(s);
		} else {
			loadFeaturedPodcasts();
		}
	}, []);

	const onSubmit = async (e) => {
		e.preventDefault();

		if (!search) {
			return;
		}
		submitSearch(search);
	};

	const submitSearch = async (search) => {
		console.log('- search:', search);
		const r = await API.call('podcast/search', {s: search});
	}

	const loadFeaturedPodcasts = async () => {
		try {
			let r = await cache.get('podcast-featured');
			if (!r) {
				r = await API.call('podcast/featured');
				cache.set('podcast-featured', r, 60 * 60);
			}
			setFeaturedPodcasts(r);
		} catch (e) {
			console.log('- fail', e);
		}
	}

	return (
		<div key="search-page" className="page">
			<div className="search-block">
				<i className="fa fa-search"></i>
				<form onSubmit={onSubmit}>
					<input
						type="text"
						placeholder="Search for a podcast name..."
						onChange={(e) => setSearch(e.target.value)}
					/>
				</form>
			</div>
			{featuredPodcasts.length > 0 && (
				<div className="featured-podcasts">
					<h2>Featured Podcasts</h2>
					<div className="podcast-list">
						{featuredPodcasts.map((podcast) => (
							<NavLink className="podcast" key={podcast.id} to={'/podcast/' + podcast.id} key={podcast.appleInfo.trackId} state={podcast}>
								<div className="podcast-image">
									<img src={podcast.appleInfo.artworkUrl100} />
								</div>
								<div className="podcast-info">
									<div className="podcast-author">{podcast.appleInfo.artistName}</div>
									<div className="podcast-title">{podcast.name}</div>
									<div className="podcast-tags">{podcast.appleInfo.genres.filter((t) => { return t !== 'Podcasts'}).join(', ')}</div>
								</div>
							</NavLink>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
