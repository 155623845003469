import { NavLink } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';

export default function FeedEpisode({ episode }) {
	const date = DateTime.fromISO(episode.isoDate);
	let duration;
	try {
		duration = Duration.fromObject({ seconds: episode.itunes.duration });
	} catch (e) {
		const [hours, minutes, seconds] = episode.itunes.duration.split(':').map(Number);
		duration = Duration.fromObject({ hours, minutes, seconds });
	}
	
	return (
		<div className="episode" >
			<div className="episode-info">
				<div className="episode-podcast">{episode.itunes?.season && ('S' + episode.itunes.season + ' E' + episode.itunes.episode + ' - ')}{date.toISODate()}</div>
				<div className="episode-title">{episode.title.replace(/\|.*/, '')}</div>
				<div className="episode-text">{episode.itunes.duration && (duration.toFormat("h'h' mm'm'"))}</div>
			</div>
		</div>
	);
}