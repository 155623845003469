import { createModel } from './model';
import { CapacitorDataStorageSqlite } from 'capacitor-data-storage-sqlite';

const useCache = createModel({

	database: 'AppCache',
	tableName: 'KeyValueStore',
	store: CapacitorDataStorageSqlite,
	isInit: false,
	
	async init(): Promise<void> {
		const self = this;

		await self.store.openStore({ database: self.database });
		await self.store.setTable({ table: self.tableName });
		self.isInit = true;

		return;
	},

	async get(key: string): Promise<any> {
		const self = this;

		// see if we were initialized yet
		if (!self.isInit) await self.init();
		

		const { value } = await self.store.get({ key });
		if (!value) return null;

		const storageObj = JSON.parse(value);
		if (storageObj.expire && storageObj.expire < Date.now()) {
			await self.store.remove({ key });
			return null;
		}

		return storageObj.value;
	},

	/**
	 * Sets a key/value pair in the cache
	 * @param key - The key to set
	 * @param value - The value to set
	 * @param expire - The number of seconds until the value expires
	 */
	async set(key: string, value: any, expire: number = 0): Promise<void> {
		const self = this;

		// see if we were initialized yet
		if (!self.isInit) await self.init();

		// create the storage object
		const storageObj = { value, expire: expire ? Date.now() + (expire * 1000) : 0 };

		await self.store.set({ key, value: JSON.stringify(storageObj) });
	}


}, {debug: false});

export default useCache;