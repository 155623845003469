//import './index.css'
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import useAuth from '../../models/auth';
import useNav from '../../models/nav';
import { SendIntent } from "send-intent";

SendIntent.checkSendIntentReceived().then((result) => {
	if (result?.url) {
		console.log('SendIntent received from root', result);
		let resultUrl = decodeURIComponent(result.url);
		window.location.href = '/ingest?url=' + resultUrl;
	}
}).catch(err => console.error(err));

export default function Root() {
	const auth = useAuth().watch(['session']);
	const nav = useNav().watch(['title']);
	const location = useLocation();

	return (
		<div id="root">
			<Header title={nav.title} path={location.pathname} />
			<div id="inner-frame">
				<Outlet />
			</div>
			<Footer />
		</div>
	);
}

function Header({title, path}) {
	return (
		<div id="header">
			{path != '/inbox' && path != '/' && <NavLink to={-1} className="right-action"><i className="fa fa-fw fa-angle-left"></i></NavLink>}
			{(path == '/inbox' || path == '/') && <div className="right-action"><i className="fa fa-fw fa-bars"></i></div>}
			<div className="title">{title}</div>
			<NavLink to={'/ingest'} className="right-action"><i className="fa fa-fw fa-plus"></i></NavLink>
		</div>
	);
}

function Footer() {

	return (
		<div id="footer">
			<NavLink to={'/'} className="footer-item active"><i className="fa fa-inbox"></i></NavLink>
			<div className="footer-item"><i className="fa fa-book"></i></div>
			<NavLink to={'/search'} className="footer-item active"><i className="fa fa-magnifying-glass"></i></NavLink>
			<NavLink to={'/settings'} className="footer-item active"><i className="fa fa-gear"></i></NavLink>
		</div>
	);
}