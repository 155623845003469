import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../api';
import useNav from '../../models/nav';
import useCache from '../../models/cache';
import Episode from '../episode';
import { DateTime, Duration } from 'luxon';
import FeedEpisode from '../FeedEpisode';

export default function PodcastPage() {
	const params = useParams();
	const { podcastId } = params;

	let [loading, setLoading] = useState(true);
	let [processingTime, setProcessingTime] = useState(5);
	const [data, setData] = useState(null);
	const nav = useNav();
	const cache = useCache();

	async function fetch() {
		try {
			let r = await cache.get('podcast' + podcastId);
			if (!r) {
				r = await API.call('podcast/get', { id: podcastId, withFeed: true });
				cache.set('podcast' + podcastId, r, 60 * 60);
			}
			setData(r);
			
		} catch (e) {
			console.log('- fail', e);
		}
		setLoading(false);
	};

	useEffect(() => {

		nav.title = '';

		// if we're coming from the inbox page, then we already have the basic info, so let's store it
		if (location.state?.podcast) {
			setData({ podcast: location.state.podcast, feed: null });
		}

		// no matter what, we need to get the full episode data with transcript
		fetch();

	}, []);

	return (
		<div id="podcast-page" className="page">
			<div key="subtitle" className="subtitle">
				{data && (
					<div className="podcast" key={data.podcast.id} key={data.podcast.appleInfo.trackId}>
						<div className="podcast-image">
							<img src={data.podcast.appleInfo.artworkUrl100} />
						</div>
						<div className="podcast-info">
							<div className="podcast-author">{data.podcast.appleInfo.artistName}</div>
							<div className="podcast-title">{data.podcast.name}</div>
							<div className="podcast-tags">{data.podcast.appleInfo.genres.filter((t) => { return t !== 'Podcasts' }).join(', ')}</div>
						</div>
					</div>
				)}
			</div>
			{data && data.episodes && (
				<div id="podcast-episodes" className="podcast-episodes">
					{data.feed.items.map((episode) => (
						<FeedEpisode key={episode.guid || episode.title} episode={episode} />
					))}
				</div>
			)}
			{loading && (
				<div key="loading">Loading...</div>
			)}
		</div>
	);
};
