import { createBrowserRouter, Navigate } from "react-router-dom";

// pages
import IngestPage from './components/pages/ingest';
import TranscriptPage from './components/pages/transcript';
import EpisodePage from './components/pages/episode';
import InboxPage from './components/pages/inbox';
import SearchPage from './components/pages/search';
import PodcastPage from "./components/pages/podcast";
import SettingsPage from "./components/pages/settings";
import PrivacyPage from "./components/pages/privacy";
import TermsPage from "./components/pages/terms";
import Root from './components/pages/root';

export default createBrowserRouter([{
		path: '/',
		element: <Root />,
		children: [
			{
				index: true,
				element: <InboxPage />,
			},
			{
				path: 'inbox',
				element: <InboxPage />,
			},
			{
				path: 'ingest',
				element: <IngestPage />,
			},
			{
				path: 'search',
				element: <SearchPage />,
			},
			{
				path: 'transcript/:episodeId',
				element: <TranscriptPage />,
			},
			{
				path: 'episode/:episodeId',
				element: <EpisodePage />,
			},
			{
				path: 'podcast/:podcastId',
				element: <PodcastPage />,
			},
			{
				path: 'settings',
				element: <SettingsPage />,
			},
			{
				path: 'privacy',
				element: <PrivacyPage />,
			},
			{
				path: 'terms',
				element: <TermsPage />,
			},
		]
	}],
	{
		basename: '/app',
	}
);